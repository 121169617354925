import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { useCookies } from 'react-cookie';

import {
  ContentfulComposeSeo,
  ContentfulComposePage,
  SeoQuery,
} from '@/../graphql-types';

interface SEOProps {
  location: Location;
  defaultPageType: string;
  defaultTitle: string;
  seo?: ContentfulComposeSeo;
}
const SEO: React.FC<SEOProps> = (props) => {
  const { site } = useStaticQuery<SeoQuery>(graphql`
    query SEO {
      site {
        ...SiteMetaDataFragment
      }
    }
  `);
  const { siteMetadata } = site;
  const { location, seo, defaultTitle, defaultPageType } = props;

  // set cookies if we have any UTMs.
  const [cookies, setCookie] = useCookies([
    'utm_campaign',
    'utm_source',
    'utm_content',
    'utm_medium',
  ]);

  React.useEffect(() => {
    const url = new URL(location?.href);
    const params = [];
    url?.search
      ?.substr(1)
      .split('&')
      .forEach((item) => {
        const temp = item.split('=');
        params.push(temp);
      });
    params.forEach((param) => {
      if (param.length > 1) {
        setCookie(param[0], param[1], {
          path: '/',
        });
      }
    });
  }, []);

  // setup seo properties
  // siteMetadata from gatsby-config will always be available for
  // title, description, and url
  // let's set a default title first:
  let pageTitle = defaultTitle || siteMetadata?.title;
  // but use the override in seo if available
  if (seo?.title) {
    pageTitle = seo?.title;
  }

  const pageTypes = {
    ContentfulPageBlog: 'Blog_Post',
    ContentfulPageCaseStudy: 'Case_Study',
    // ContentfulPageGuides: 'Guides',
    ContentfulPageContactUs: 'Contact_Us',
    ContentfulPageCustomerStory: 'Customer_Story',
    ContentfulPageEvent: 'Event',
    ContentfulPageFlexible: 'Flexible',
    ContentfulPageLanding: 'Home',
    ContentfulPageProduct: 'Product',
    ContentfulPageProductFeature: 'Product_Feature',
    ContentfulPageResourceCategory: 'Resource_Category',
    ContentfulPageResourcesOverview: 'Resources_Overview',
    ContentfulPageSolutionsOverview: 'Solutions_Overview',
    ContentfulPageSolution: 'Solution',
    ContentfulPageVideo: 'Video',
    ContentfulPageWebinar: 'Webinar',
  };

  // set the default page type
  let pageTypeMeta = pageTypes[defaultPageType];
  if (seo?.pageType) {
    pageTypeMeta = seo?.pageType;
  }

  const title = pageTitle;
  const description = seo?.description || siteMetadata?.description;
  const url = `${siteMetadata?.siteUrl}${location?.pathname}`;
  const canonicalUrl = seo?.canonicalUrl;
  const image = seo?.featuredImage?.file?.url || siteMetadata?.featuredImage;
  const imageAlt =
    seo?.featuredImage?.description || siteMetadata?.featuredImageAlt;

  let noIndex, noFollow, showRobots, robotsSep;
  if (seo?.no_index || process.env.GATSBY_NOINDEX) {
    noIndex = 'noindex';
    showRobots = true;
  }
  if (seo?.no_follow) {
    noFollow = 'nofollow';
    showRobots = true;
  }
  if (noIndex && noFollow) {
    robotsSep = ', ';
  }

  let keywords;
  if (seo?.keywords) {
    keywords = seo?.keywords.join('|');
  }

  const index_follow_pages = [
    '/amazon-web-services',
    '/axikon-7822e55',
    '/case-studies/milton-aws',
    '/case-studies/quincy-aws',
    '/core-welcome',
    '/partners/stinson',
    '/partners/tcs',
    '/smartview-approach-welcome',
  ];
  const is_index_follow_page = index_follow_pages.includes(location?.pathname);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="twitter:title" content={title} />
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta property="og:url" content={url} />
      {image && <meta name="image" content={image} />}
      {image && <meta property="og:image" content={image} />}
      {image && <meta name="twitter:image" content={image} />}
      {imageAlt && <meta property="og:image:alt" content={imageAlt} />}
      {imageAlt && <meta name="twitter:image:alt" content={imageAlt} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {is_index_follow_page && <meta name="robots" content={`index, follow`} />}
      {!is_index_follow_page && showRobots && (
        <meta name="robots" content={`${noIndex}${robotsSep}${noFollow}`} />
      )}
      {process.env.NODE_ENV === 'production' && (
        <meta
          name="google-site-verification"
          content="-tSx1-KwktCOBFexQbJijc6RxG52k9DgiokfHtq53Do"
        />
      )}
      <meta name="referrer" content="no-referrer" />

      {/* Interaction Studio */}
      <meta name="evg-page-type" content={pageTypeMeta} />
      <meta name="evg-page-title" content={title} />
      <meta name="datePublished" content={seo?.createdAt} />
      <meta name="dateModified" content={seo?.updatedAt} />
      {keywords && <meta name="evg-keywords" content={keywords} />}
      <script
        type="text/javascript"
        src={process.env.GATSBY_INTERACTION_STUDIO_BEACON}
      ></script>
      {/* Crazy Egg tracking script */}
      <script
        type="text/javascript"
        src="//script.crazyegg.com/pages/scripts/0116/8986.js"
      ></script>
      {/* Google tag (gtag.js) */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-0ZRK4QVJFH"
      ></script>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-1062147192"
      ></script>
      {/* Meta Pixel Code */}
      <script>
        {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '363146093808971');
        fbq('track', 'PageView');`}
      </script>
      <noscript>{`<img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=363146093808971&ev=PageView&noscript=1"
      />`}</noscript>
      {/* End Meta Pixel Code */}
      {/* Start LinkedIn Code */}
      <script>
        {`
          _linkedin_partner_id = "4522740"; 
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; 
          window._linkedin_data_partner_ids.push(_linkedin_partner_id); 
        `}
      </script>
      <script>
        {`
          (function(l){ 
            if (!l){
              window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; 
              window.lintrk.q=[]
            } 
            var s = document.getElementsByTagName("script")[0]; 
            var b = document.createElement("script"); 
            b.type = "text/javascript";
            b.async = true; 
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; 
            s.parentNode.insertBefore(b, s);
          })(window.lintrk);
        `}
      </script>
      {/* End LinkedIn Code */}
      {/* Start accessiBe Code */}
      <script>
        {`
            (function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'left', leadColor : '#146FF8', triggerColor : '#146FF8', triggerRadius : '50%', triggerPositionX : 'left', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'big', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'medium', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerOffsetX : 30, triggerOffsetY : 30, triggerRadius : '20' } }); }; h.appendChild(s); })();
          `}
      </script>
      {/* End accessiBe Code */}
      {location?.pathname === '/thank-you' && (
        /* Event snippet for GAds Lead Form - Thank You Page conversion page */
        <script>
          {`gtag('event', 'conversion', {'send_to': 'AW-1062147192/QL3yCKi35q8YEPiovPoD'});`}
        </script>
      )}
    </Helmet>
  );
};
export default SEO;
